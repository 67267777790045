import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Layout from '../components/Layout'
import { withPrefix } from 'gatsby'

const MessageContainer = styled.div`
  padding: 48px 0;
  min-height: 60vh;
`

const message = ({ data }) => {
  const post = data.btswebViewtypeJson

  // Cleans up post title in case there is no image attached

  if (post.Title.slice(-3) === 'jpg') {
    post.Title = post.Title.slice(0, -4)
  }

  const altTextRegex = new RegExp(/<img.*?alt="(.*?)".*>/gi)

  let match = ''
  let newHtml = JSON.parse(JSON.stringify(post.Caption))
  let lastIndexOf = 0

  // Loop thru all regex.exec() matches to find and replace all image blobs
  while ((match = altTextRegex.exec(newHtml)) !== null) {
    // Get the Alt Text values first to get the name of the picture
    const fileName = match[1].split(',')[1]

    // Search graphQL to find image based on above fileName
    const findPublicURL = data.allFile.edges.find(
      pic => pic.node.base === fileName
    )

    // Now find first image tag
    const regex = /<img.*?src="(.*?)"/

    // search only the html that is left from the last search
    let src = regex.exec(newHtml.substring(lastIndexOf))[1]

    newHtml = newHtml.replace(src, withPrefix(findPublicURL.node.publicURL))

    lastIndexOf =
      newHtml.indexOf(findPublicURL.node.publicURL) +
      findPublicURL.node.publicURL.length
  }

  // get all Header tags
  const hTagsRegex = new RegExp(/<h\d>(.*?)<\/h\d>/g)
  let arrHTags = []

  while ((match = hTagsRegex.exec(newHtml)) !== null) {
    let hTagCopy = JSON.parse(JSON.stringify(match[0]))

    let testhtc = ''

    testhtc = hTagCopy.replace(
      '>',
      ` id="${match[1]
        .replace(/ /g, '-')
        .replace(/&nbsp;/g, '')
        .toLowerCase()}">`
    )

    newHtml = newHtml.replace(match[0], testhtc)
    arrHTags.push(match[1].replace(/&nbsp;/g, ''))
  }

  const newSideNav = arrHTags.map(tag => {
    const newTag = tag.replace(/(<([^>]+)>)/gi, '')
    return (
      <li>
        <AnchorLink
          href={`#${tag
            .replace(/ /g, '-')
            .replace(/&nbsp;/g, '')
            .toLowerCase()}`}
        >
          {newTag.replace(/\w+/g, function(w) {
            return w[0].toUpperCase() + w.slice(1).toLowerCase()
          })}
        </AnchorLink>
      </li>
    )
  })

  return (
    <Layout>
      <MessageContainer className="usa-grid">
        <aside className="usa-width-one-fourth">
          <nav>
            <ul className="usa-sidenav-list">
              <li>{post.Title}</li>
              {newSideNav}
            </ul>
          </nav>
        </aside>

        <div className="usa-width-three-fourths">
          <h1 className="post-title">{post.Title}</h1>
          <p id="things" className="post-author">
            written by {post.Author}
          </p>
          <div dangerouslySetInnerHTML={{ __html: newHtml }} />
        </div>
      </MessageContainer>
    </Layout>
  )
}

export default message;

export const query = graphql`
  query OneViewTypeMessage($id: Int) {
    btswebViewtypeJson(ID: { eq: $id }) {
      ID
      Author
      PubDate
      Caption
      Title
      Status
    }
    allFile {
      edges {
        node {
          base
          publicURL
        }
      }
    }
  }
`
